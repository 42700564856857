import { createSlice } from "@reduxjs/toolkit";

const initialStockAuditState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    stockAuditForEdit: undefined,
    currentTab: "overview",
    lastError: null,
    message: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const stockAuditSlice = createSlice({
    name: "stockAudits",
    initialState: initialStockAuditState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error.clientMessage;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.message = null;

            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getStockAuditById
        stockAuditFetched: (state, action) => {
            state.actionsLoading = false;
            state.stockAuditForEdit = action.payload.stockAuditForEdit;
            state.error = null;

        },
        // getStockAuditChart
        stockAuditChartFetched: (state, action) => {

            state.actionsLoading = false;
            state.stockAuditChart = action.payload.stockAuditChart;
            state.error = null;

        },
        // getStockAuditChart
        stockAuditHourlyChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.stockAuditChartHour = action.payload.stockAuditChartHour;
            state.error = null;

        },
        // getStockAuditChart
        stockAuditHourlyZoneChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.stockAuditChartHourZone = action.payload.stockAuditChartHourZone;
            state.error = null;

        },
        // get list data
        stockAuditsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.stockAuditForEdit = undefined;
            state.stockAuditChart = undefined;
            state.stockAuditChartHour = undefined;
            state.stockAuditChartHourZone = undefined;
        },
        // createStockAudit
        stockAuditCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        // updateStockAudit
        stockAuditUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.message = action.payload.msgResponse;
        },
        // stockAuditDeleted
        stockAuditDeleted: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        closeAudit: (state,action) => {
             state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        setMessage: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        setTab: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.currentTab = action.payload.tab;
        },


    }
});
