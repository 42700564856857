import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/permissions`;

// READ
export function getAllPermissions() {
    return axios.get(API_URL);
}

export function permissionRegenerate() {
    return axios.post(`${API_URL}/regenerate`);
}
