import { toAbsoluteUrl } from "../_metronic/_helpers";

export function getInitMenuList(intl) {
  const MENU_LIST = [

    {
      to: "/inventory",
      title: intl.formatMessage({
        id: "MENU.TRANSACTIONS.INVENTORY",
        defaultMessage: "Inventory",
      }),
      svgSource: toAbsoluteUrl("/media/svg/icons/Shopping/Box3.svg"),
      accessId: "material_list",
    },
    {
      to: "/transactions",
      title: intl.formatMessage({
        id: "MENU.TRANSACTIONS",
        defaultMessage: "Transactions",
      }),
      svgSource: toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-list.svg"),
      accessId: "transaction_list",
    },
    {
      to: "/StockAssociations",
      title: intl.formatMessage({
        id: "MENU.STOCK_ASSOCIATIONS",
        defaultMessage: "Stock Associations",
      }),
      svgSource: toAbsoluteUrl("/media/svg/icons/Shopping/Barcode-read.svg"),
      accessId: "stock_unit_create",
    },
    // {
    //   to: "/massStockAssociations",
    //   title: intl.formatMessage({
    //     id: "MENU.STOCK_ASSOCIATIONS",
    //     defaultMessage: "Mass Stock Associations",
    //   }),
    //   svgSource: toAbsoluteUrl("/media/svg/icons/Shopping/Barcode-read.svg"),
    //   accessId: "material_list",
    // },
    {
      to: "/stockAudits",
      title: intl.formatMessage({
        id: "MENU.STOCK_AUDIT",
        defaultMessage: "Stock Audits",
      }),
      svgSource: toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-check.svg"),
      accessId: "stock_audit_list",
    },
    // {
    //   title: intl.formatMessage({
    //     id: "MENU.REPORTING",
    //     defaultMessage: "Reporting",
    //   }),
    //   subMenu: [
    //     {
    //       to: "/reporting/inventory",
    //       title: intl.formatMessage({
    //         id: "MENU.REPORTING.INVENTORY",
    //         defaultMessage: "Inventory",
    //       }),
    //       svgSource: toAbsoluteUrl("/media/svg/icons/Shopping/Chart-bar1.svg"),
    //     },
    //   ],
    // },
    {
      // to: "/configurations",
      title: intl.formatMessage({
        id: "MENU.CONFIGURATIONS",
        defaultMessage: "Configurations",
      }),
      subMenu: [

        {
          to: "/configurations/locations",
          title: intl.formatMessage({
            id: "MENU.CONFIGURATIONS.LOCATIONS",
            defaultMessage: "Locations",
          }),
          svgSource: toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg"),
          subMenu: [
            {
              to: "/configurations/locations/viewall",
              title: intl.formatMessage({
                id: "MENU.COMMON.VIEW_ALL",
                defaultMessage: "View All",
              }),
              accessId: "location_list",
            },
            {
              to: "/configurations/locations/create",
              title: intl.formatMessage({
                id: "MENU.COMMON.CREATE",
                defaultMessage: "Create",
              }),
              accessId: "location_create",
            },
          ],
        },
        {
          to: "/configurations/suppliers",
          title: intl.formatMessage({
            id: "MENU.CONFIGURATIONS.SUPPLIERS",
            defaultMessage: "Suppliers",
          }),
          svgSource: toAbsoluteUrl("/media/svg/icons/Shopping/Loader.svg"),
          subMenu: [
            {
              to: "/configurations/suppliers/viewall",
              title: intl.formatMessage({
                id: "MENU.COMMON.VIEW_ALL",
                defaultMessage: "View All",
              }),
              accessId: "supplier_list",
            },
            {
              to: "/configurations/suppliers/create",
              title: intl.formatMessage({
                id: "MENU.COMMON.CREATE",
                defaultMessage: "Create",
              }),
              accessId: "supplier_create",
            },
          ],
        },
        {
          to: "/configurations/manufacturers",
          title: intl.formatMessage({
            id: "MENU.CONFIGURATIONS.MANUFACTURERS",
            defaultMessage: "Manufacturers",
          }),
          svgSource: toAbsoluteUrl("/media/svg/icons/Tools/Hummer2.svg"),
          subMenu: [
            {
              to: "/configurations/manufacturers/viewall",
              title: intl.formatMessage({
                id: "MENU.COMMON.VIEW_ALL",
                defaultMessage: "View All",
              }),
              accessId: "manufacturer_list",
            },
            {
              to: "/configurations/manufacturers/create",
              title: intl.formatMessage({
                id: "MENU.COMMON.CREATE",
                defaultMessage: "Create",
              }),
              accessId: "manufacturer_create",
            },
          ],
        },
        {
          to: "/configurations/categories",
          title: intl.formatMessage({
            id: "MENU.CONFIGURATIONS.CATEGORY",
            defaultMessage: "Categories",
          }),
          svgSource: toAbsoluteUrl("/media/svg/icons/General/Star.svg"),
          subMenu: [
            {
              to: "/configurations/categories/viewall",
              title: intl.formatMessage({
                id: "MENU.COMMON.VIEW_ALL",
                defaultMessage: "View All",
              }),
              accessId: "category_list",
            },
            {
              to: "/configurations/categories/create",
              title: intl.formatMessage({
                id: "MENU.COMMON.CREATE",
                defaultMessage: "Create",
              }),
              accessId: "category_create",
            },
          ],
        },
      ],
    },
  ];

  const Menu_List = MENU_LIST.map((menu) => {
    if (menu.accessKeys) return menu;

    if (menu.subMenu) {
      let menuContent = menu.subMenu.map((menu2) => {
        if (menu2.accessKeys) return menu2;

        if (menu2.accessId) {
          if (!menu.accessKeys) menu.accessKeys = [];
          menu.accessKeys.push(menu2.accessId);
        }

        if (menu2.subMenu) {
          let menu2Content = menu2.subMenu.map((menu3) => {
            if (menu3.accessKeys) return menu3;

            if (menu3.accessId) {
              if (!menu2.accessKeys) menu2.accessKeys = [];
              menu2.accessKeys.push(menu3.accessId);
            }

            return menu3;
          });

          menu2.subMenu = menu2Content;
        }

        if (menu2.accessKeys) {
          if (!menu.accessKeys) menu.accessKeys = [];
          menu.accessKeys.push(...menu2.accessKeys);
        }

        return menu2;
      });

      menu.subMenu = menuContent;
    }

    return menu;
  });

  return Menu_List;
}
