import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/locations`;

// CREATE =>  POST: add a new product to the server
export function createLocation(location) {
    return axios.post(API_URL, { ...location });
}

// READ
export function getAllLocations() {
    return axios.get(API_URL);
}

export function getPaginatedLocations(queryParams) {
    return axios.post(`${API_URL}/list`, { ...queryParams });
}

export function getRecentLocations() {
    return axios.get(`${API_URL}/recent`);
}


export function getLocationById(locationId) {
    return axios.get(`${API_URL}/${locationId}`);
}



// // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// // items => filtered/sorted result
// export function findProducts(queryParams) {
//     return axios.post(`${API_URL}/find`, { queryParams });
// }

// UPDATE => PUT: update the location on the server
export function updateLocation(locationId, location) {
    return axios.put(`${API_URL}/${locationId}`, { ...location });
}


// DELETE => delete the product from the server
export function deleteLocation(locationId) {
    return axios.delete(`${API_URL}/${locationId}`);
}
