import React from "react";
import { Route } from "react-router-dom";
import { Content } from "./Content";
import { shallowEqual, useSelector } from "react-redux";
import { NoAccess } from "./NoAccess"

export function ContentRoute({ children, component, accessId, render, ...props }) {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );
  return (
    <Route {...props}>
      {routeProps => {


        if (typeof children === "function") {
          return <Content>{children(routeProps)}</Content>;
        }

        if (!routeProps.match) {
          return null;
        }


        if (accessId && user.permissions.filter((module) => module.name === accessId).length === 0) {
          return <NoAccess />;
        }


        if (children) {
          return <Content>{children}</Content>;
        }

        if (component) {
          return (
            <Content>{React.createElement(component, routeProps)}</Content>
          );
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}
