import React from "react";


export const DropdownTopbarItemToggler = React.forwardRef((props, ref) => {
  const { isOpen, setIsOpen } = props;

  return (
    <div
      ref={ref}
      className="topbar-item"
      onClick={e => {
        e.preventDefault();
        props.onClick(e);
        if(setIsOpen) {
          setIsOpen(!isOpen);
        }
        
      }}
    >
      {props.children}

    </div>
  );
});

DropdownTopbarItemToggler.displayName = 'DropdownTopbarItemToggler';
