import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/users`;
export const USERLOCATION_API_URL = `${process.env.REACT_APP_API_URL}/userlocations`
export const ACCOUNTTYPE_API_URL = `${process.env.REACT_APP_API_URL}/accounttypes`;
export const SUBSCRIPTION_URL = `${process.env.REACT_APP_API_URL}/subscriptions/select/user`;

// CREATE =>  POST: add a new product to the server
export function createUser(user) {
    return axios.post(API_URL, { ...user });
}

// READ
export function getAllUsers() {
    return axios.get(`${API_URL}`);
}

export function getPaginatedUsers(queryParams) {
    return axios.post(`${API_URL}/list`, { ...queryParams });
}


export function getUserById(userId) {
    return axios.get(`${API_URL}/${userId}`);
}

export function getAccountTypes() {
    return axios.get(`${ACCOUNTTYPE_API_URL}`)
}


// UPDATE => PUT: update the user on the server
export function updateUser(userId, user) {
    return axios.put(`${API_URL}/${userId}`, { ...user });
}

export function changeUserPassword(userId, request) {
    return axios.put(`${API_URL}/changepassword/${userId}`, { ...request })
}

export function resetMultipleUserPassword(ids) {
    return axios.put(`${API_URL}/selectedresetpassword`, { ...ids })
}

export function updatePermissionSets(values) {
    return axios.put(`${API_URL}/permissionsets`, { ...values });
}

// DELETE => delete the user from the server
export function deleteUser(userId) {
    return axios.delete(`${API_URL}/${userId}`);
}



export function setUserSubscription(userId, subscription) {
    return axios.put(`${API_URL}/${userId}`, { ...subscription });
}

export function getUserSubscriptionList() {
    return axios.get(`${SUBSCRIPTION_URL}`);
}