import { createSlice } from "@reduxjs/toolkit";

const initialManufacturerState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    manufacturerForEdit: undefined,
    lastError: null,
    message: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const manufacturerSlice = createSlice({
    name: "manufacturers",
    initialState: initialManufacturerState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error.clientMessage;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.message = null;

            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getManufacturerById
        manufacturerFetched: (state, action) => {
            state.actionsLoading = false;
            state.manufacturerForEdit = action.payload.manufacturerForEdit;
            state.error = null;

        },
        // getManufacturerChart
        manufacturerChartFetched: (state, action) => {

            state.actionsLoading = false;
            state.manufacturerChart = action.payload.manufacturerChart;
            state.error = null;

        },
        // getManufacturerChart
        manufacturerHourlyChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.manufacturerChartHour = action.payload.manufacturerChartHour;
            state.error = null;

        },
        // getManufacturerChart
        manufacturerHourlyZoneChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.manufacturerChartHourZone = action.payload.manufacturerChartHourZone;
            state.error = null;

        },
        // get list data
        manufacturersFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.manufacturerForEdit = undefined;
            state.manufacturerChart = undefined;
            state.manufacturerChartHour = undefined;
            state.manufacturerChartHourZone = undefined;
        },
        // createManufacturer
        manufacturerCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        // updateManufacturer
        manufacturerUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.message = action.payload.msgResponse;
        },
        // manufacturerDeleted
        manufacturerDeleted: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        setMessage: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
        },


    }
});
