/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, Fragment } from "react";
import SVG from "react-inlinesvg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { NavLink } from "react-router-dom";
import Highlight from 'react-highlighter';
import { FormattedMessage } from "react-intl";
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

// const Highlighted = ({ text = '', highlight = '' }) => {
//   if (!highlight.trim()) {
//     return <span>{text}</span>
//   }
//   const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
//   const parts = text.split(regex)
//   return (
//     <span>
//       {parts.filter(part => part).map((part, i) => (
//         regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
//       ))}
//     </span>
//   )
// }

export function SearchResult({ data, searchValue }) {

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);


  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return (
      <div
        style={{ maxHeight: "325px", overflow: "hidden" }}
        className="quick-search-wrapper scroll ps ps--active-y"
      >
        <div className="quick-search-result">
          <div className="text-muted d-none"><FormattedMessage id="GENERAL.MESSAGE.NO_RECORD" defaultMessage="No record found" /></div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ maxHeight: "325px", overflow: "hidden" }}
      className="quick-search-wrapper scroll ps ps--active-y"
    >
      <PerfectScrollbar
        options={perfectScrollbarOptions}
        className="scroll"
        style={{ maxHeight: "325px", position: "relative" }}
      >
        <div className="search-result">


          <div className="font-size-sm text-primary font-weight-bolder text-uppercase">
            <FormattedMessage id="HEADER.SEARCH.LABEL.MODULES" defaultMessage="Modules" />
          </div>

          <div
            data-menu-vertical="1"
            className={`aside-menu ${layoutProps.asideClassesFromConfig}`}
            {...layoutProps.asideMenuAttr}
          >

            <ul className={`menu-nav ${layoutProps.ulClasses} pt-0`}>

              {data && data.map((parentModule, i) => {

                if (!parentModule.subMenu) {
                  return (

                    <li
                      key={i}
                      className={`menu-item`}
                    >
                      <NavLink className="menu-link" to={parentModule.to}>
                        {parentModule.svgSource && (
                          <span className="svg-icon menu-icon">
                            <SVG src={parentModule.svgSource} title={parentModule.title} />
                          </span>
                        )}
                        <span className="menu-text"><Highlight search={searchValue}>{parentModule.title}</Highlight></span>
                      </NavLink>
                    </li>
                  )
                }

                return (
                  <Fragment key={i}>
                    <li className="menu-section " key={i}>
                      <h4 className="menu-text"><Highlight search={searchValue}>{parentModule.title}</Highlight></h4>
                      <i className="menu-icon flaticon-more-v2"></i>

                    </li>
                    {parentModule.subMenu && parentModule.subMenu.map((module, i) => {

                      if (!module.subMenu) {
                        return (
                          <li
                            key={i}
                            className={`menu-item`}
                          >
                            <NavLink className="menu-link" to={module.to}>
                              {module.svgSource && (
                                <span className="svg-icon menu-icon">
                                  <SVG src={module.svgSource} title={module.title} />
                                </span>
                              )}

                              <span className="menu-text"><Highlight search={searchValue}>{module.title}</Highlight></span>
                            </NavLink>
                          </li>
                        )
                      }

                      return (
                        <li
                          key={i}
                          className={`menu-item menu-item-submenu menu-item-open`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink className="menu-link menu-toggle" to={module.to}>
                            {module.svgSource && (
                              <span className="svg-icon menu-icon">
                                <SVG src={module.svgSource} title={module.title} />
                              </span>
                            )}

                            <span className="menu-text"><Highlight search={searchValue}>{module.title}</Highlight></span>
                          </NavLink>
                          <div className="menu-submenu ">
                            <i className="menu-arrow" />
                            <ul className="menu-subnav">
                              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                  <span className="menu-text"><Highlight search={searchValue}>{module.title}</Highlight></span>
                                </span>
                              </li>

                              {module.subMenu.map((moduleSubMenu, i) => (
                                <li
                                  key={i}
                                  className={`menu-item`}
                                  aria-haspopup="true"
                                >
                                  <NavLink className="menu-link" to={moduleSubMenu.to}>
                                    <i className="menu-bullet menu-bullet-dot">
                                      <span />
                                    </i>
                                    <span className="menu-text"><Highlight search={searchValue}>{moduleSubMenu.title}</Highlight></span>
                                  </NavLink>
                                </li>
                              ))}

                            </ul>
                          </div>
                        </li>
                      )


                    })}
                  </Fragment>
                )

              })}

            </ul>
            {/* end: Module Section */}
          </div>

          {/* <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
            Modules
          </div>
          <div className="mb-10">
            <div className="d-flex align-items-center flex-grow-1 mb-2">
              <div className="symbol symbol-45 symbol-circle flex-shrink-0">
                <div className="symbol-label">
                  <i className="flaticon-psd text-primary"></i>
                </div>
              </div>
              <div className="d-flex flex-column ml-3 mt-2 mb-2">
                <a
                  href="#"
                  className="font-weight-bold text-dark text-hover-primary"
                >
                  79 PSD files generated
                </a>
                <span className="font-size-sm font-weight-bold text-muted">
                  by Grog John
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-grow-1 mb-2">
              <div className="symbol symbol-45 symbol-circle flex-shrink-0">
                <div className="symbol-label">
                  <i className="flaticon2-supermarket text-warning"></i>
                </div>
              </div>
              <div className="d-flex flex-column ml-3 mt-2 mb-2">
                <a
                  href="#"
                  className="font-weight-bold text-dark text-hover-primary"
                >
                  $2900 worth products sold
                </a>
                <span className="font-size-sm font-weight-bold text-muted">
                  Total 234 items
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-grow-1 mb-2">
              <div className="symbol symbol-45 symbol-circle flex-shrink-0">
                <div className="symbol-label">
                  <i className="flaticon-safe-shield-protection text-info"></i>
                </div>
              </div>
              <div className="d-flex flex-column ml-3 mt-2 mb-2">
                <a
                  href="#"
                  className="font-weight-bold text-dark text-hover-primary"
                >
                  4 New items submitted
                </a>
                <span className="font-size-sm font-weight-bold text-muted">
                  Marketing Manager
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-grow-1 mb-2">
              <div className="symbol symbol-45 symbol-circle flex-shrink-0">
                <div className="symbol-label">
                  <i className="flaticon-safe-shield-protection text-warning"></i>
                </div>
              </div>
              <div className="d-flex flex-column ml-3 mt-2 mb-2">
                <a
                  href="#"
                  className="font-weight-bold text-dark text-hover-primary"
                >
                  4 New items submitted
                </a>
                <span className="font-size-sm font-weight-bold text-muted">
                  Marketing Manager
                </span>
              </div>
            </div>
          </div> */}

        </div>

      </PerfectScrollbar>
    </div>
  );
}
