import { createSlice } from "@reduxjs/toolkit";

const initialSupplierState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    supplierForEdit: undefined,
    lastError: null,
    message: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const supplierSlice = createSlice({
    name: "suppliers",
    initialState: initialSupplierState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error.clientMessage;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.message = null;

            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getSupplierById
        supplierFetched: (state, action) => {
            state.actionsLoading = false;
            state.supplierForEdit = action.payload.supplierForEdit;
            state.error = null;

        },
        // getSupplierChart
        supplierChartFetched: (state, action) => {

            state.actionsLoading = false;
            state.supplierChart = action.payload.supplierChart;
            state.error = null;

        },
        // getSupplierChart
        supplierHourlyChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.supplierChartHour = action.payload.supplierChartHour;
            state.error = null;

        },
        // getSupplierChart
        supplierHourlyZoneChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.supplierChartHourZone = action.payload.supplierChartHourZone;
            state.error = null;

        },
        // get list data
        suppliersFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.supplierForEdit = undefined;
            state.supplierChart = undefined;
            state.supplierChartHour = undefined;
            state.supplierChartHourZone = undefined;
        },
        // createSupplier
        supplierCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        // updateSupplier
        supplierUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.message = action.payload.msgResponse;
        },
        // supplierDeleted
        supplierDeleted: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        setMessage: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
        },


    }
});
