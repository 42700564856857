import { lazy } from "react";


const useLazyLoad = (componentImport) =>
    lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem(
                'page-has-been-refreshed'
            ) || 'false'
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem(
                'page-has-been-refreshed',
                'false'
            );

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                window.localStorage.setItem(
                    'page-has-been-refreshed',
                    'true'
                );
                return window.location.reload();
            }

            throw error;
        }
    });

export default useLazyLoad;