import { createSlice } from "@reduxjs/toolkit";

const initialTransactionState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    exportData: [],
    isExporting: false,
    transactionForEdit: undefined,
    currentTab: "overview",
    lastError: null,
    message: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action",
    export: "export",
};

export const transactionSlice = createSlice({
    name: "transactions",
    initialState: initialTransactionState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error.clientMessage;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else if (action.payload.callType === callTypes.export) {
                state.isExporting = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.message = null;

            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else if (action.payload.callType === callTypes.export) {
                state.isExporting = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else if (action.payload.callType === callTypes.export) {
                state.isExporting = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getTransactionById
        transactionFetched: (state, action) => {
            state.actionsLoading = false;
            state.transactionForEdit = action.payload.transactionForEdit;
            state.error = null;

        },
        // getTransactionChart
        transactionChartFetched: (state, action) => {

            state.actionsLoading = false;
            state.transactionChart = action.payload.transactionChart;
            state.error = null;

        },
        // getTransactionChart
        transactionHourlyChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.transactionChartHour = action.payload.transactionChartHour;
            state.error = null;

        },
        // getTransactionChart
        transactionHourlyZoneChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.transactionChartHourZone = action.payload.transactionChartHourZone;
            state.error = null;

        },
        // get list data
        transactionsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.transactionForEdit = undefined;
            state.transactionChart = undefined;
            state.transactionChartHour = undefined;
            state.transactionChartHourZone = undefined;
        },

        // get list data
        exportDataFetched: (state, action) => {
            const { entities } = action.payload;
            state.isExporting = false;
            state.exportData = entities;
        },
        // createTransaction
        transactionCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        // updateTransaction
        transactionUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.message = action.payload.msgResponse;
        },
        // transactionDeleted
        transactionDeleted: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        setMessage: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        setTab: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.currentTab = action.payload.tab;
        },


    }
});
