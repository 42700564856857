import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

export function TextArea({
    mask,
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type = "text",
    required,
    ...props
}) {

    const { name, value = "" } = field;


    return (
        <>
            {label && <label>{label}{required && (<span style={{ color: 'red' }}> *</span>)}</label>}
            <textarea
                className={getFieldCSSClasses(touched[name], errors[name])}
                value={value}
                {...field}
                {...props}
            />
            {withFeedbackLabel && touched[name] && (
                <FieldFeedbackLabel
                    error={errors[name]}
                    touched={touched[name]}
                    label={label}
                    type={type}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}


        </>
    );
}
