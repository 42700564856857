import React from 'react'
import { toAbsoluteUrl } from '../../../_helpers';
import { Card } from "./../../../_partials/controls";

export function NoAccess() {
    return (
        <div>
            <Card className="gutter-b">
                <div className="d-flex flex-column flex-root">
                    <div
                        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl("/media/error/bg6.jpg")})`
                        }}
                    >
                        <h1
                            className="font-size-sm-100 font-weight-boldest text-white mt-15"
                            style={{ fontSize: "150px" }}
                        >
                            401
                        </h1>
                        <p className="font-size-h3 font-weight-bold text-white">
                            OOPS! Access Denied

                        </p>
                        <p className="font-size-h5 font-weight-light text-white">
                            Please check with your administrator.
                        </p>
                    </div>
                </div>
            </Card>
        </div>
    )
}
