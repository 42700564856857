export const forceInputLowercase = (e) => {
    var start = e.target.selectionStart;
    var end = e.target.selectionEnd;
    e.target.value = e.target.value.toLowerCase();
    e.target.setSelectionRange(start, end);
}

export const forceInputNotAllowedSpace = (e) => {
    let valueHolder = e.target.value.replace(/\s/g, '');
    e.target.value = valueHolder;
}

