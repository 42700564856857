import { createSlice } from "@reduxjs/toolkit";

const initialCategoryState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    categoryForEdit: undefined,
    lastError: null,
    message: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const categorySlice = createSlice({
    name: "category",
    initialState: initialCategoryState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error.clientMessage;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.message = null;

            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getCategoryById
        categoryFetched: (state, action) => {
            state.actionsLoading = false;
            state.categoryForEdit = action.payload.categoryForEdit;
            state.error = null;

        },
        // getCategoryChart
        categoryChartFetched: (state, action) => {

            state.actionsLoading = false;
            state.categoryChart = action.payload.categoryChart;
            state.error = null;

        },
        // getCategoryChart
        categoryHourlyChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.categoryChartHour = action.payload.categoryChartHour;
            state.error = null;

        },
        // getCategoryChart
        categoryHourlyZoneChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.categoryChartHourZone = action.payload.categoryChartHourZone;
            state.error = null;

        },
        // get list data
        categoryAllFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.categoryForEdit = undefined;
            state.categoryChart = undefined;
            state.categoryChartHour = undefined;
            state.categoryChartHourZone = undefined;
        },
        // createCategory
        categoryCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        // updateCategory
        categoryUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.message = action.payload.msgResponse;
        },
        // categoryDeleted
        categoryDeleted: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        setMessage: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
        },

    }
});
