import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/dashboards`;

// Statistics Panel
export function getStatisticsPanel() {
    return axios.get(`${API_URL}/administratorheader`);
}

export function getUserLogins() {
    return axios.get(`${API_URL}/useractivity`);
}



export function getDeviceActivity() {
    return axios.get(`${API_URL}/devicecountactivity`);
}


export function getRecentLogins() {
    return axios.get(`${API_URL}/activelogin`);
}

export function getTenantSubscription() {
    return axios.get(`${API_URL}/tenantsubscription`);
}


export function getExportStatus() {
    return axios.get(`${API_URL}/exportstatus`);
}