import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/inventoryItem`;

// CREATE =>  POST: add a new product to the server
export function createInventoryItem(inventoryItem) {
    return axios.post(API_URL, { ...inventoryItem });
}

// READ
export function getAllInventoryItem() {
    return axios.get(API_URL);
}

export function getPaginatedInventoryItem(queryParams) {
    return axios.post(`${API_URL}/list`, { ...queryParams });
}

export function getInventoryItemById(inventoryItemId) {
    return axios.get(`${API_URL}/${inventoryItemId}`);
}



// // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// // items => filtered/sorted result
// export function findProducts(queryParams) {
//     return axios.post(`${API_URL}/find`, { queryParams });
// }

// UPDATE => PUT: update the inventoryItem on the server
export function updateInventoryItem(inventoryItemId, inventoryItem) {
    return axios.put(`${API_URL}/${inventoryItemId}`, { ...inventoryItem });
}


// DELETE => delete the product from the server
export function deleteInventoryItem(inventoryItemId) {
    return axios.delete(`${API_URL}/${inventoryItemId}`);
}
