import { createSlice } from "@reduxjs/toolkit";

const initialLocationState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    recentLocations: [],
    locationForEdit: undefined,
    lastError: null,
    message: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const locationSlice = createSlice({
    name: "locations",
    initialState: initialLocationState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error.clientMessage;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.message = null;

            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getLocationById
        locationFetched: (state, action) => {
            state.actionsLoading = false;
            state.locationForEdit = action.payload.locationForEdit;
            state.error = null;

        },
        // getLocationChart
        locationChartFetched: (state, action) => {

            state.actionsLoading = false;
            state.locationChart = action.payload.locationChart;
            state.error = null;

        },
        // getLocationChart
        locationHourlyChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.locationChartHour = action.payload.locationChartHour;
            state.error = null;

        },
        // getLocationChart
        locationHourlyZoneChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.locationChartHourZone = action.payload.locationChartHourZone;
            state.error = null;

        },
        // get list data
        locationsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.locationForEdit = undefined;
            state.locationChart = undefined;
            state.locationChartHour = undefined;
            state.locationChartHourZone = undefined;
        },
        // createLocation
        locationCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        // updateLocation
        locationUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.message = action.payload.msgResponse;
        },
        // locationDeleted
        locationDeleted: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        recentLocationsFetched: (state,action) => {
            state.error = null;
            state.recentLocations = action.payload.recentLocations;
            state.actionsLoading = false;
        },
        setMessage: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
        },


    }
});
