/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { getInitMenuList } from "../../../../../app/MenuList";
import { useSelector, shallowEqual } from "react-redux";
import { injectIntl } from "react-intl";
export function AsideMenuList({ layoutProps, intl }) {

  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const location = useLocation();
  const [menuList, setMenuList] = useState([]);


  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const popUpProps = {
    'aria-haspopup': "true",
    'data-menu-toggle': "hover"
  }

  useEffect(() => {
    const menuList = getInitMenuList(intl);
    setMenuList(menuList);
  }, [intl])

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        <li className="menu-section">
          <h4 className="menu-text">MAIN</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        {menuList && menuList.map((element, i) => {

          if (element.accessKeys) {
            let accessCTR = 0;

            element.accessKeys.forEach(element => {
              if (user && user.permissions.filter((module) => module.name === element).length > 0) accessCTR++;
            });

            if (!accessCTR) return null;
          }

          return (
            <React.Fragment key={i}>
              {element.subMenu && !element.to ? (
                <>
                  {/* begin::section */}
                  <li className="menu-section">
                    <h4 className="menu-text">{element.title}</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                  </li>
                  {/* end:: section */}

                  {element.subMenu && (

                    <>

                      {element.subMenu.map((element2, i) => {

                        if (element2.accessKeys) {
                          let accessCTR = 0;

                          element2.accessKeys.forEach(element => {
                            if (user && user.permissions.filter((module) => module.name === element).length > 0) accessCTR++;
                          });

                          if (!accessCTR) return null;
                        }

                        if (element2.accessId && user && user.permissions.filter((module) => module.name === element2.accessId).length === 0) return null;

                        return (
                          <li
                            key={i}
                            {...(element2.subMenu ? { ...popUpProps } : {})}
                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                              element2.to,
                              element2.subMenu ? true : false,
                            )}`}
                          >
                            <NavLink className={`menu-link ${element2.subMenu ? 'menu-toggle' : ''}`} to={element2.to}>
                              {element2.svgSource && (
                                <span className="svg-icon menu-icon">
                                  <SVG src={element2.svgSource} title={element2.title} />
                                </span>
                              )}

                              {!element2.svgSource && (
                                <i className="menu-bullet menu-bullet-dot"><span /></i>
                              )}

                              <span className="menu-text">
                                {element2.title}
                              </span>
                              {element2.subMenu && (
                                <i className="menu-arrow"></i>
                              )}
                            </NavLink>
                            {element2.subMenu && (
                              <div className="menu-submenu ">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                  <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                    <span className="menu-link">
                                      <span className="menu-text">{element2.title}</span>
                                    </span>
                                  </li>
                                  {element2.subMenu.map((element3, i) => {
                                    if (element3.accessId && user && user.permissions.filter((module) => module.name === element3.accessId).length === 0) return null;
                                    return (
                                      <li
                                        key={i}
                                        {...(element3.subMenu ? { ...popUpProps } : {})}
                                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                                          element3.to,
                                          element3.subMenu ? true : false,
                                        )}`}
                                      >
                                        <NavLink
                                          className="menu-link menu-toggle"
                                          to={element3.to}
                                        >
                                          <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                          </i>
                                          <span className="menu-text">{element3.title}</span>
                                        </NavLink>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </div>

                            )}
                          </li>



                        )
                      })}

                    </>
                  )
                  }

                </>
              ) : (
                <>
                  {(element.accessId && user && user.permissions.filter((module) => module.name === element.accessId).length > 0) ? (
                    <li
                      className={`menu-item ${getMenuItemActive(element.to, false)}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to={element.to}>
                        {element.svgSource && (
                          <span className="svg-icon menu-icon">
                            <SVG src={element.svgSource} title={element.title} />
                          </span>
                        )}
                        <span className="menu-text">{element.title}</span>
                      </NavLink>
                    </li>) : null}
                </>

              )}
            </React.Fragment>
          )
        })}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

export default injectIntl(AsideMenuList)