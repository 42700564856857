export const getArrayKeyValue = (array, key) => {
    if (array.length === 0) return [];

    return array.map(item => item[key] !== undefined ? item[key] : null);
}

// Returs array with first item is the main Account Type and second will be the subAccountType
export const getAccountType = (accountType) => {

    let resultAccount = [];

    if (!accountType || !accountType.name) return ["user", "user"];

    if (accountType.name) resultAccount.push(accountType.name.toLowerCase());

    if (accountType.subAccountTypes && accountType.subAccountTypes.length > 0 && accountType.subAccountTypes[0].name) {
        resultAccount.push(accountType.subAccountTypes[0].name.toLowerCase());
    } else {
        resultAccount.push("user");
    }

    return resultAccount;
}


export const hasPermission = (permissionArr, permissionName) => {

    if (permissionArr.length === 0) return false;

    return permissionArr.filter(item => item.name === permissionName).length > 0 ? true : false;
}

export const filterRequest = (arrKeys, arrValues) => {
    let filterKeys = {};
    arrKeys.map((key) => {
        let keyValues = {};
        if ((arrValues[key] === undefined) || (arrValues[key] !== undefined && arrValues[key] === "")) {
            keyValues[key] = null;
        } else {
            keyValues[key] = arrValues[key];
        }
        filterKeys = { ...filterKeys, ...keyValues };
        return key;
    })
    return filterKeys;
}
