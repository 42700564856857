import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/token`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const PERMISSION_URL = `${process.env.REACT_APP_API_URL}/permission`;
export const USER_URL = `${process.env.REACT_APP_API_URL}/users`;
export const FORGOT_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/password`;


export function login(username, password) {
  return axios.post(LOGIN_URL, { username, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function changePassword(changePassword) {
  return axios.put(`${USER_URL}/password`, changePassword);
}

export function changeProfile(user) {
  return axios.put(`${USER_URL}/profile`, user);
}

export function forgotPassword(forgotPassword) {
  return axios.post(`${FORGOT_PASSWORD_URL}/reset`, { ...forgotPassword });
}

export function resetPassword(resetPassword) {
  return axios.put(`${FORGOT_PASSWORD_URL}/reset`, { ...resetPassword });
}

// export function requestPassword(email) {
//   return axios.post(USER_URL, { email });
// }

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(`${USER_URL}/profile`);
}

export function getUserPermission() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(PERMISSION_URL);
}
