import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { SearchResult } from "./SearchResult";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../../_partials/dropdowns";
import { getInitMenuList } from './../../../../../../app/MenuList';
import { FormattedMessage, injectIntl } from "react-intl";

function SearchDropdown({ intl }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [modules, setModules] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  let timeoutId;

  const clearTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
  };

  const handleSearchChange = (event) => {
    setData(null);
    setSearchValue(event.target.value);

    if (event.target.value.length > 2) {
      clearTimeout();

      setLoading(true);

      // simulate getting search result
      timeoutId = setTimeout(() => {

        const searchModules = [];
        const searchText = searchValue.toLowerCase();

        modules.forEach((item) => {
          if (item.title.toLowerCase().indexOf(searchText) !== -1) {
            searchModules.push(item);
            return;
          }

          if (!item.subMenu) return;

          const currentSubMenu = [];

          item.subMenu.forEach((module) => {

            if (module.title.toLowerCase().indexOf(searchText) !== -1) {
              currentSubMenu.push(module);
              return;
            }

            if (!module.subMenu) return;

            const currentModuleSubMenu = [];

            module.subMenu.forEach((moduleSubMenu) => moduleSubMenu.title.toLowerCase().indexOf(searchText) !== -1 ? currentModuleSubMenu.push(moduleSubMenu) : null);

            if (currentModuleSubMenu.length > 0) {
              currentSubMenu.push({ ...module, subMenu: currentModuleSubMenu });
            }

          })

          if (currentSubMenu.length > 0) {
            searchModules.push({ ...item, subMenu: currentSubMenu });
          }


        })

        setData(searchModules);
        setLoading(false);
      }, 500);
    }
  };


  const clear = () => {
    setData(null);
    setSearchValue("");
  };

  useEffect(() => {

    const modules = getInitMenuList(intl);
    setModules(modules);

    return () => {
      clearTimeout();

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.search.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1"
            id="kt_quick_search_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
            </span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown
          alignRight
          drop="down"
          onToggle={() => {
            setData(null);
            setLoading(false);
            setSearchValue("");
          }}
          id="kt_quick_search_toggle"
        >
          <Dropdown.Toggle as={DropdownTopbarItemToggler}>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="search-panel-tooltip"><FormattedMessage id="HEADER.SEARCH.LABEL.QUICK_SEARCH" defaultMessage="Quick search" /></Tooltip>}
            >
              <div className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                  />
                </span>
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <div
              id="kt_quick_search_dropdown"
              className={clsx("quick-search quick-search-dropdown", {
                "quick-search-has-result": data && data.length,
              })}
            >
              <form className="quick-search-form">
                <div className="input-group">
                  <div className={`input-group-prepend`}>
                    <span className="input-group-text">
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Search.svg"
                          )}
                        />
                      </span>
                    </span>
                  </div>
                  <input
                    type="text"
                    autoFocus={true}
                    placeholder={intl.formatMessage({ id: "HEADER.SEARCH.INPUT.SEARCH.PLACEHOLDER", defaultMessage: "Search..." })}
                    value={searchValue}
                    onChange={handleSearchChange}
                    className="form-control"
                  />


                  <div
                    className={`input-group-append`}
                  >
                    <span className="input-group-text">
                      <kbd><FormattedMessage id="HEADER.SEARCH.SHORTCUT.ESC" defaultMessage="esc" /></kbd>
                    </span>
                  </div>

                  {searchValue.length > 0 && (<div
                    className={`input-group-append ${loading ? "spinner spinner-sm spinner-primary" : ""
                      }")}`}
                  >
                    <span className="input-group-text">
                      <i
                        style={{
                          display:
                            loading && searchValue && searchValue.length > 0
                              ? "none"
                              : "flex",
                        }}
                        onClick={clear}
                        className="quick-search-close ki ki-close icon-sm text-muted"
                      />
                    </span>
                  </div>)}

                </div>
              </form>
              <SearchResult data={data} searchValue={searchValue} />
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
export default injectIntl(SearchDropdown);