import React, { useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";
// import DatePicker from "react-datepicker";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from "moment";
const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};


// Format for the date
// moment().format('LT');    1:27 PM
// moment().format('LTS');   1:27:01 PM
// moment().format('L');     09/09/2021
// moment().format('l');     9/9/2021
// moment().format('LL');    September 9, 2021
// moment().format('ll');    Sep 9, 2021
// moment().format('LLL');   September 9, 2021 1:27 PM
// moment().format('lll');   Sep 9, 2021 1:27 PM
// moment().format('LLLL');  Thursday, September 9, 2021 1:27 PM
// moment().format('llll');  Thu, Sep 9, 2021 1:27 PM


export function DateRangePickerField({ withFeedbackLabel = true, format, valueFormat = "MM/DD/YYYY HH:mm", isCustomRange = false, customRange, showCustomRangeLabel = true, timePicker = false, timePicker24Hour = false, initialValues, ...props }) {

    const { setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
    const [field] = useField(props);

    const [state, setState] = useState({
        start: moment().hours(0).minutes(0).seconds(0),
        end: moment().hours(23).minutes(0).seconds(0),
    })
    const { start, end } = state;

    useEffect(() => {
        if (!initialValues || !initialValues.start || !initialValues.end) return;

        setState({ ...initialValues });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        setFieldValue(field.name, `${start.format(valueFormat)} - ${end.format(valueFormat)}`);
        setFieldTouched(field.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    const handleCallback = (start, end) => {
        setState({ start, end });
    };

    const label = start.format(format) + ' - ' + end.format(format);

    return (
        <>
            {props.label && <label>{props.label}</label>}

            <DateRangePicker

                style={{ width: "100%" }}
                initialSettings={{
                    timePicker: timePicker,
                    timePicker24Hour: timePicker24Hour,
                    startDate: start.toDate(),
                    endDate: end.toDate(),
                    showCustomRangeLabel: showCustomRangeLabel,
                    ranges: isCustomRange && customRange ? customRange : {
                        Today: [moment().hours(0).minutes(0).seconds(0).toDate(), moment().hours(23).minutes(0).seconds(0).toDate()],
                        Yesterday: [
                            moment().subtract(1, 'days').hours(0).minutes(0).seconds(0).toDate(),
                            moment().subtract(1, 'days').hours(23).minutes(0).seconds(0).toDate(),
                        ],
                        'Last 7 Days': [
                            moment().subtract(6, 'days').hours(0).minutes(0).seconds(0).toDate(),
                            moment().add(1, 'days').hours(0).minutes(0).seconds(0).toDate(),
                        ],
                        'Last 30 Days': [
                            moment().subtract(29, 'days').hours(0).minutes(0).seconds(0).toDate(),
                            moment().hours(23).minutes(0).seconds(0).toDate(),
                        ],
                        'This Month': [
                            moment().startOf('month').hours(0).minutes(0).seconds(0).toDate(),
                            moment().endOf('month').hours(23).minutes(0).seconds(0).toDate(),
                        ],
                        'Last Month': [
                            moment().subtract(1, 'month').startOf('month').hours(0).minutes(0).seconds(0).toDate(),
                            moment().subtract(1, 'month').endOf('month').hours(23).minutes(0).seconds(0).toDate(),
                        ],
                    },
                }}
                onCallback={handleCallback}
            >
                <div
                    id="reportrange"
                    className={getFieldCSSClasses(touched[field.name], errors[field.name])}
                    // className="col-4"
                    style={{
                        background: '#fff',
                        cursor: 'pointer',
                        padding: '10px 10px',
                        width: '100%',
                    }}
                    {...field}
                    {...props}
                >
                    <i className="fa fa-calendar"></i>&nbsp;
                    <span>{label}</span> <i className="fa fa-caret-down"></i>
                </div>
            </DateRangePicker>

            {withFeedbackLabel && touched[field.name] && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={props.label}
                    type={"text"}
                />
                // <div className="invalid-datepicker-feedback">
                //     {errors[field.name].toString()}
                // </div>
            )}
        </>
    );
}
