import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "./authRedux";
import { getUserByToken } from "./authCrud";

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { authToken, validUntil } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
      validUntil: auth.validUntil
    }),
    shallowEqual
  );

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {

          const { data } = await getUserByToken();
          const { success, payload: user } = data;

          if (!success) {
            dispatch(props.fulfillUser(undefined));
            setShowSplashScreen(false);
            return;
          }
          dispatch(props.fulfillUser(user));

        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    const validateToken = (validUntil) => {
      let validDate = new Date(validUntil);
      let today = new Date();

      var diff_time = validDate.getTime() - today.getTime();

      var diff_day = diff_time / (1000 * 3600 * 24);

      return (diff_day > 0) ? true : false;
    }

    //Check if the token is still valid.
    let isTokenValid = validateToken(validUntil);

    if (authToken && isTokenValid) {
      requestUser();
    } else {
      dispatch(props.fulfillUser(undefined));
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);
