import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/categories`;

// CREATE =>  POST: add a new product to the server
export function createCategory(category) {
    return axios.post(API_URL, { ...category });
}

// READ
export function getAllCategory() {
    return axios.get(API_URL);
}

export function getPaginatedCategory(queryParams) {
    return axios.post(`${API_URL}/list`, { ...queryParams });
}

export function getCategoryById(categoryId) {
    return axios.get(`${API_URL}/${categoryId}`);
}



// // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// // items => filtered/sorted result
// export function findProducts(queryParams) {
//     return axios.post(`${API_URL}/find`, { queryParams });
// }

// UPDATE => PUT: update the category on the server
export function updateCategory(categoryId, category) {
    return axios.put(`${API_URL}/${categoryId}`, { ...category });
}


// DELETE => delete the product from the server
export function deleteCategory(categoryId) {
    return axios.delete(`${API_URL}/${categoryId}`);
}
