import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { forgotPassword } from "../_redux/authCrud";
import { toAbsoluteUrl } from './../../../../_metronic/_helpers';
import SVG from "react-inlinesvg";

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "GENERAL.VALIDATION.REQUIRED",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      forgotPassword(values)
        .then((response) => {
          let { status, data } = response;

          if (status !== 200) {
            setStatus(intl.formatMessage({
              id: "AUTH.FORGOT.NETWORK.ERROR",
            }));
            return;
          }

          const { success, message } = data;

          if (!success) {
            setStatus(message);
          }

          setIsRequested(true);

        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(intl.formatMessage({
            id: "AUTH.FORGOT.NETWORK.ERROR",
          }));
        });
    },
  });

  return (
    <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
      {isRequested && (
        <div className="login-form login-reset" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <span className={`svg-icon svg-icon-xxl-10x svg-icon-success`}>
              <SVG
                title="Reset Success"
                src={toAbsoluteUrl("/media/svg/icons/Communication/Readed-mail.svg")}
              />
            </span>
            <h3 className="font-size-h2 font-weight-normal mt-5"><FormattedMessage id="AUTH.FORGOT.REQUEST.SUCCESS.TITLE" defaultMessage="Check Your Email" /></h3>
            <h5 className="font-size-h5 font-weight-normal mt-5"><FormattedMessage id="AUTH.FORGOT.REQUEST.SUCCESS.MESSAGE_1" defaultMessage="Please check the email address" /> {formik?.values?.email ?? ''} <FormattedMessage id="AUTH.FORGOT.REQUEST.SUCCESS.MESSAGE_2" defaultMessage="for instructions to reset your password." /></h5>
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <Link to="/auth">
              <button
                type="button"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <FormattedMessage id="AUTH.GENERAL.BACK_TO_LOGIN" defaultMessage="Back to Login" />
              </button>
            </Link>
          </div>
        </div>
      )}

      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1"><FormattedMessage id="AUTH.FORGOT.TITLE" defaultMessage="Forgotten Password?" /></h3>
            <div className="text-muted font-weight-bold">
              <FormattedMessage id="AUTH.FORGOT.DESC"  defaultMessage="Enter your email to reset your password"/>
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" defaultMessage="Submit" />
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" defaultMessage="Cancel" />
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
