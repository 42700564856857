import React, { useState, useEffect, useCallback, useRef } from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import clsx from "clsx";
import ScrollToFetch from 'react-scroll-to-fetch'
import PerfectScrollbar from "react-perfect-scrollbar";
const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];


    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

export function AutocompletePaginated({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type = "text",
    options,
    displayData = false,
    caseSensitive = false,
    autoSelect = false,
    runOnChangeEmpty = false,
    customOnChange = false,
    runOnChange,
    required,
    selected=undefined,
    fetchFn,
    resultSize=10,
    ...props
}) {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [show, setShow] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    let timeoutId;

    const { name, value = "" } = field;

    const searchFieldRef = useRef();

    const clearTimeout = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = undefined;
        }
    };


    const handleSearchChange = (event) => {
        setData([]);
        setSearchValue(event.target.value);

        if (event.target.value.length > 2) {
            clearTimeout();
            setLoading(true);
            // Filter on Sieve
            // simulate getting search result
            timeoutId = setTimeout(() => {
                fetchData(1)
                setLoading(false);
            }, 500);
        }
    };

    const handleSearchBlur = () => {

        setTimeout(() => {
            setShow(false);
        }, 500);
    }

    const handleInputFocus = () => {
        setShow(true);
        clearTimeout();
        timeoutId = setTimeout(() => {
            searchFieldRef.current.focus()
        }, 500);

    }

    const clear = () => {
        setData([]);
        setSearchValue("");
        setFieldTouched(name)
        setFieldValue(name, "");

        fetchData(1);

        if (customOnChange && runOnChangeEmpty) runOnChange("", "", setFieldValue);

        searchFieldRef.current.focus();
    };

    const fetchData = useCallback((page) => {
        return new Promise((resolve,reject)=>{
            const request = {
                filters: `name@=${searchValue}`,
                sorts: "",
                page: page,
                pageSize: resultSize
            };
            fetchFn(request).then(res => {
                const { success, message, payload} = res.data;
                if(success) {
                    setIsFinished(parseInt(page) === parseInt(payload.totalPages) ? true : false);
                    let data = payload.categories.map((item) => {
                        return { label: item.name, value: item.id }
                    })
                    setData((oldData) => [...oldData, ...data])
                    resolve()
                }else{
                    reject(message);
                }
                
            })
            .catch(error => {
                reject(error.message);
            });
        });
    },[fetchFn,resultSize, searchValue])


    useEffect(() => {

        if (!selected || selected === "" || selected === null || !selected.name) {
            setSearchValue("");
            return;
        }
        setSearchValue(selected.name);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    useEffect(() => {
        if (!displayData) return;
        if (searchValue !== "") return;


        setTimeout(() => {
            fetchData(1);
            setLoading(false);
        }, 500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayData, searchValue]);

    useEffect(() => {
        return () => {
            clearTimeout();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const perfectScrollbarOptions = {
        wheelSpeed: 2,
        wheelPropagation: false,
    };


    const handleSelectedValue = (value, label) => event => {
        // event.prevetDefault();
        setFieldTouched(name)
        setFieldValue(name, value);
        setSearchValue(label);
        setShow(false);

        if (customOnChange) runOnChange(value, label, setFieldValue);

    }

    return (
        <>

            <div className="dropdown">

                {label && <label>{label}{required && (<span style={{ color: 'red' }}> *</span>)}</label>}


                <input
                    type="hidden"
                    autoComplete="off"
                    name={name}
                    value={value || ""}
                    onChange={field.onChange}
                // {...field}
                />

                <input
                    autoComplete="off"
                    type={type}
                    name={name}
                    value={searchValue}
                    onChange={field.onChange}
                    className={getFieldCSSClasses(touched[name], errors[name])}
                    onFocus={handleInputFocus}
                    // {...field}
                    {...props}

                />



                {withFeedbackLabel && touched[name] && (
                    <FieldFeedbackLabel
                        error={errors[name]}
                        touched={touched[name]}
                        label={label}
                        type={type}
                        customFeedbackLabel={customFeedbackLabel}
                    />
                )}




                <div className={`w-100 dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg ${show ? 'show' : ''}`} style={{ position: "absolute", top: "0px", left: "0px", margin: "0px" }} >
                    <div
                        id="kt_quick_search_dropdown"
                        className={clsx("quick-search quick-search-dropdown", {
                            "quick-search-has-result": data && data.length,
                        })}
                    >
                        <div className="quick-search-form">
                            <div className="input-group">
                                <input
                                    autoFocus
                                    ref={searchFieldRef}
                                    autoComplete="off"
                                    className="form-control"
                                    // className={getFieldCSSClasses(touched[name], errors[name])}
                                    onChange={handleSearchChange}
                                    onBlur={handleSearchBlur}
                                    value={searchValue}

                                    // {...field}
                                    {...props}
                                    id={props.id ? `search-autocomplete-${props.id}` : "search-autocomplete"}

                                />

                                <div
                                    className={`input-group-append ${loading ? "spinner spinner-sm spinner-primary" : ""
                                        }")}`}
                                >
                                    <span className="input-group-text">
                                        <i
                                            style={{
                                                display:
                                                    loading && searchValue && searchValue.length > 0
                                                        ? "none"
                                                        : "flex",
                                            }}
                                            onClick={clear}
                                            className="quick-search-close ki ki-close icon-sm text-muted"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div
                            style={{ maxHeight: "325px", overflow: "hidden" }}
                            className="quick-search-wrapper scroll ps ps--active-y"
                        >
                            <PerfectScrollbar
                                options={perfectScrollbarOptions}
                                id="perfectScroll"
                                className="scroll"
                                style={{ maxHeight: "325px", position: "relative" }}
                            >
                                <ScrollToFetch
                                    fetch={fetchData} 
                                    finished={isFinished}
                                    initialLoad={true}
                                    loader={<div style={{textAlign:'center'}} className="text-muted">Loading...</div>}
                                    successMessage={<div style={{textAlign:'center'}} className="text-muted">No more results</div>}
                                    scrollParent="perfectScroll"
                                >
                                    <div className="dropdown-result">
                                        {data && data.map((item, i) => {
                                            return (<button type="button" className="dropdown-item" key={i} tabIndex={i} onClick={handleSelectedValue(item.value, item.label)}>{item.label}</button>)
                                        })}
                                    </div>
                                </ScrollToFetch>
                            </PerfectScrollbar>
                        </div>
                        
                    </div>
                </div>


            </div>



        </>
    );
}
