import { createSlice } from "@reduxjs/toolkit";

const initialCategoryTypeState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    categoryTypeForEdit: undefined,
    lastError: null,
    message: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const categoryTypeSlice = createSlice({
    name: "categoryTypes",
    initialState: initialCategoryTypeState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error.clientMessage;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.message = null;

            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getCategoryTypeById
        categoryTypeFetched: (state, action) => {
            state.actionsLoading = false;
            state.categoryTypeForEdit = action.payload.categoryTypeForEdit;
            state.error = null;

        },
        // getCategoryTypeChart
        categoryTypeChartFetched: (state, action) => {

            state.actionsLoading = false;
            state.categoryTypeChart = action.payload.categoryTypeChart;
            state.error = null;

        },
        // getCategoryTypeChart
        categoryTypeHourlyChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.categoryTypeChartHour = action.payload.categoryTypeChartHour;
            state.error = null;

        },
        // getCategoryTypeChart
        categoryTypeHourlyZoneChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.categoryTypeChartHourZone = action.payload.categoryTypeChartHourZone;
            state.error = null;

        },
        // get list data
        categoryTypesFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.categoryTypeForEdit = undefined;
            state.categoryTypeChart = undefined;
            state.categoryTypeChartHour = undefined;
            state.categoryTypeChartHourZone = undefined;
        },
        // createCategoryType
        categoryTypeCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        // updateCategoryType
        categoryTypeUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.message = action.payload.msgResponse;
        },
        // categoryTypeDeleted
        categoryTypeDeleted: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        setMessage: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
        },


    }
});
