import { createSlice } from "@reduxjs/toolkit";

const initialStockAuditItemState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    discrepancyEntities: [],
    totalDiscrepancyCount: 0,
    stockAuditItemForEdit: undefined,
    currentTab: "overview",
    lastError: null,
    message: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const stockAuditItemSlice = createSlice({
    name: "stockAuditItems",
    initialState: initialStockAuditItemState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error.clientMessage;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.message = null;

            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getStockAuditItemById
        stockAuditItemFetched: (state, action) => {
            state.actionsLoading = false;
            state.stockAuditItemForEdit = action.payload.stockAuditItemForEdit;
            state.totalDiscrepancyCount = 0;
            state.error = null;

        },
        // getStockAuditItemChart
        stockAuditItemChartFetched: (state, action) => {

            state.actionsLoading = false;
            state.stockAuditItemChart = action.payload.stockAuditItemChart;
            state.error = null;

        },
        // getStockAuditItemChart
        stockAuditItemHourlyChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.stockAuditItemChartHour = action.payload.stockAuditItemChartHour;
            state.error = null;

        },
        // getStockAuditItemChart
        stockAuditItemHourlyZoneChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.stockAuditItemChartHourZone = action.payload.stockAuditItemChartHourZone;
            state.error = null;

        },
        // get list data
        stockAuditItemsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.stockAuditItemForEdit = undefined;
            state.stockAuditItemChart = undefined;
            state.stockAuditItemChartHour = undefined;
            state.stockAuditItemChartHourZone = undefined;
        },
        // createStockAuditItem
        stockAuditItemCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        // updateStockAuditItem
        stockAuditItemUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.message = action.payload.msgResponse;
        },
        // stockAuditItemDeleted
        stockAuditItemDeleted: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        stockAuditItemDiscrepancyFetched: (state,action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.discrepancyEntities = entities;
            state.totalDiscrepancyCount = totalCount;
        },
        setMessage: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        setTab: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.currentTab = action.payload.tab;
        },


    }
});
