import { createSlice } from "@reduxjs/toolkit";

const initialInventoryState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    currentTab: "overview",
    materialSearching: false,
    inventoryForEdit: undefined,
    lastError: null,
    message: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action",
    find: "find",
};

export const inventorySlice = createSlice({
    name: "inventory",
    initialState: initialInventoryState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error.clientMessage;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else if(action.payload.callType === callTypes.find) {
                state.materialSearching = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.message = null;

            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else if(action.payload.callType === callTypes.find) {
                state.materialSearching = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else if(action.payload.callType === callTypes.find) {
                state.materialSearching = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getInventoryById
        inventoryFetched: (state, action) => {
            state.actionsLoading = false;
            state.inventoryForEdit = action.payload.inventoryForEdit;
            state.error = null;

        },
        // getInventoryChart
        inventoryChartFetched: (state, action) => {

            state.actionsLoading = false;
            state.inventoryChart = action.payload.inventoryChart;
            state.error = null;

        },
        // getInventoryChart
        inventoryHourlyChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.inventoryChartHour = action.payload.inventoryChartHour;
            state.error = null;

        },
        // getInventoryChart
        inventoryHourlyZoneChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.inventoryChartHourZone = action.payload.inventoryChartHourZone;
            state.error = null;

        },
        // get list data
        inventoryAllFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.inventoryForEdit = undefined;
            state.inventoryChart = undefined;
            state.inventoryChartHour = undefined;
            state.inventoryChartHourZone = undefined;
        },
        // createInventory
        inventoryCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        // updateInventory
        inventoryUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.message = action.payload.msgResponse;
        },
        // inventoryDeleted
        inventoryDeleted: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        setMessage: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        setTab: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.currentTab = action.payload.tab;
        },


    }
});
