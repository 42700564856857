import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/subaccounttypes`;

// CREATE =>  POST: add a new product to the server
export function createSubAccountType(subAccountType) {
    return axios.post(API_URL, { ...subAccountType });
}

// READ
export function getAllSubAccountTypes() {
    return axios.get(API_URL);
}

export function getPaginatedSubAccountTypes(queryParams) {
    return axios.post(`${API_URL}/list`, { ...queryParams });
}

export function getSubAccountTypeById(subAccountTypeId) {
    return axios.get(`${API_URL}/${subAccountTypeId}`);
}


export function updateSubAccountType(subAccountTypeId, subAccountType) {
    return axios.put(`${API_URL}/${subAccountTypeId}`, { ...subAccountType });
}

export function deleteSubAccountType(subAccountTypeId) {
    return axios.delete(`${API_URL}/${subAccountTypeId}`);
}
