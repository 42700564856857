import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import MaskInput from 'react-maskinput';
const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Input({
  mask,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  elementAppend,
  elementPrepend,
  helpText,
  required,
  ...props
}) {

  const { name, value = "" } = field;


  return (
    <>
      {label && <label>{label}{required && (<span style={{ color: 'red' }}> *</span>)}</label>}
      <div className="input-group">
        {elementPrepend && (<div className="input-group-prepend">{elementPrepend}</div>)}

        {mask ? (<MaskInput
          mask={mask}
          type={type}
          className={getFieldCSSClasses(touched[name], errors[name])}
          {...field}
          {...props}
          value={value}
        />) : (<input
          type={type}
          className={getFieldCSSClasses(touched[name], errors[name])}
          {...field}
          {...props}
          value={value}
        />)}

        {elementAppend && (<div className="input-group-append">{elementAppend}</div>)}
        {!touched[name] && helpText && (<div className="form-text text-muted d-block w-100">{helpText}</div>)}
        {withFeedbackLabel && touched[name] && (
          <FieldFeedbackLabel
            error={errors[name]}
            touched={touched[name]}
            label={label}
            type={type}
            customFeedbackLabel={customFeedbackLabel}
          />
        )}
      </div>

    </>
  );
}
