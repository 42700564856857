import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    accountTypes: [],
    userForEdit: undefined,
    lastError: null,
    message: null,
    error: null,
    userSubscriptionList: undefined,
    userSubscriptionListLoading: false,
};
export const callTypes = {
    list: "list",
    action: "action",
    userSubscriptionList: "userSubscriptionList",
};

export const usersSlice = createSlice({
    name: "users",
    initialState: initialUserState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error.clientMessage;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else if (action.payload.callType === callTypes.userSubscriptionList) {
                state.userSubscriptionListLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else if (action.payload.callType === callTypes.userSubscriptionList) {
                state.userSubscriptionListLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else if (action.payload.callType === callTypes.userSubscriptionList) {
                state.userSubscriptionListLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getUserById
        userFetched: (state, action) => {
            state.actionsLoading = false;
            state.userForEdit = action.payload.userForEdit;
            state.error = null;

        },
        accountTypesFetched: (state, action) => {
            const { accountTypes } = action.payload;
            state.actionsLoading = false;
            state.accountTypes = accountTypes;
            state.error = null;

        },
        // get list data
        usersFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // createUser
        userCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        // updateUser
        userUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.message = action.payload.msgResponse;
        },
        // userDeleted
        userDeleted: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        userChangePassword: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.message = action.payload.msgResponse;
        },
        usersPermissionSetUpdate: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        userSubscriptionListFetched: (state, action) => {
            state.userSubscriptionListLoading = false;
            state.error = null;
            state.userSubscriptionList = action.payload.subscriptions;
        },
        userSetSubscription: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },

        setMessage: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
        }

    }
});
