import { createSlice } from "@reduxjs/toolkit";

const initialItemState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    fetchTag: false,
    itemForEdit: undefined,
    lastError: null,
    message: null,
    error: null,
    randomEpcs: null,
};
export const callTypes = {
    list: "list",
    action: "action",
    getNewTag: "getNewTag",
};

export const itemSlice = createSlice({
    name: "items",
    initialState: initialItemState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error.clientMessage;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else if (action.payload.callType === callTypes.getNewTag) {
                state.fetchTag = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.message = null;

            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else if (action.payload.callType === callTypes.getNewTag) {
                state.fetchTag = true;
            }else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else if (action.payload.callType === callTypes.getNewTag) {
                state.fetchTag = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getRandomEpcs
        randomEpcsFetched: (state, action) => {
            const { randomEpcs } = action.payload;
            state.actionsLoading = false;
            state.randomEpcs = randomEpcs;
            console.log(state.randomEpcs);
            state.error = null;
        },
        // getItemById
        itemFetched: (state, action) => {
            state.actionsLoading = false;
            state.itemForEdit = action.payload.itemForEdit;
            state.floorPlans = action.payload.itemForEdit.floorPlans;
            state.error = null;

        },
        // getItemChart
        itemChartFetched: (state, action) => {

            state.actionsLoading = false;
            state.itemChart = action.payload.itemChart;
            state.error = null;

        },
        // getItemChart
        itemHourlyChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.itemChartHour = action.payload.itemChartHour;
            state.error = null;

        },
        // getItemChart
        itemHourlyZoneChartFetched: (state, action) => {
            state.actionsLoading = false;
            state.itemChartHourZone = action.payload.itemChartHourZone;
            state.error = null;

        },
        // get list data
        itemsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.itemForEdit = undefined;
            state.floorPlans = undefined;
            state.itemChart = undefined;
            state.itemChartHour = undefined;
            state.itemChartHourZone = undefined;
        },
        itemFetchTag: (state, action) => {
            state.fetchTag = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        // createItem
        itemCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        // updateItem
        itemUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.message = action.payload.msgResponse;
        },
        // itemDeleted
        itemDeleted: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        setMessage: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        floorPlanDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.floorPlans = state.floorPlans.filter(item => item.id !== action.payload.id);
        },
        floorPlanUpdated: (state, action) => {
            const { id, floorPlan } = action.payload;
            state.error = null;
            state.actionsLoading = false;
            state.floorPlans = state.floorPlans.map((data) => {

                if (data.id === id) {
                    return floorPlan;
                }

                return data;
            });
        }

    }
});
