import React, { useState, useEffect, useRef } from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";

const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];


    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

export function Autocomplete({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type = "text",
    options,
    displayData = false,
    caseSensitive = false,
    autoSelect = false,
    runOnChangeEmpty = false,
    customOnChange = false,
    runOnChange,
    required,
    ...props
}) {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [show, setShow] = useState(false);
    let timeoutId;

    const { name, value = "" } = field;

    const searchFieldRef = useRef();

    const clearTimeout = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = undefined;
        }
    };

    const loadOptions = (options) => {

        if (options.length === 0 || !Array.isArray(options)) {
            return [];
        }

        //Check if it has value and label and return 1 dimentional select 
        if (options[0].value === undefined || options[0].label === undefined) {
            let newOption = options.map((option) => {
                return { value: option, label: option };
            })

            return newOption;
        }

        return options;
    }

    const handleSearchChange = (event) => {
        setData(null);
        setSearchValue(event.target.value);

        if (event.target.value.length > 2) {
            clearTimeout();
            setLoading(true);

            const loadedOptions = loadOptions(options);

            let newOptions = [];
            if (caseSensitive) {
                newOptions = loadedOptions.filter((item) => item.label.includes(event.target.value))
            } else {
                newOptions = loadedOptions.filter((item) => item.label.toLowerCase().includes(event.target.value.toLowerCase()))
            }


            // simulate getting search result
            timeoutId = setTimeout(() => {
                setData(newOptions);
                setLoading(false);
            }, 500);
        }
    };

    const handleSearchBlur = () => {

        setTimeout(() => {
            setShow(false);
        }, 500);
    }

    const handleInputFocus = () => {
        setShow(true);
        clearTimeout();
        timeoutId = setTimeout(() => {
            searchFieldRef.current.focus()
        }, 500);

    }

    const clear = () => {
        setData(null);
        setSearchValue("");
        setFieldTouched(name)
        setFieldValue(name, "");

        if (customOnChange && runOnChangeEmpty) runOnChange("", "", setFieldValue);

        searchFieldRef.current.focus();
    };



    useEffect(() => {
        if (!displayData) return;
        if (searchValue !== "") return;
        const loadedOptions = loadOptions(options);

        setTimeout(() => {
            setData(loadedOptions);
            setLoading(false);
        }, 500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayData, options, searchValue]);

    useEffect(() => {
        if (!autoSelect || !options || options.length === 0 || value !== "") return;

        const loadedOptions = loadOptions(options);
        const firstSelected = loadedOptions.find((item, i) => i === 0)
        if (firstSelected) {
            setFieldValue(name, firstSelected.value);
            if (customOnChange) runOnChange(firstSelected.value, firstSelected.label, setFieldValue);
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoSelect, value, options, customOnChange])

    useEffect(() => {

        if (value === "") {
            setSearchValue("");
            return;
        }

        const loadedOptions = loadOptions(options);
        const slcOption = loadedOptions.find((item) => item.value === value);
        if (slcOption) {
            setSearchValue(slcOption.label);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, options])

    useEffect(() => {
        return () => {
            clearTimeout();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ResultData = ({ data }) => {

        if (!data) {
            return null;
        }

        if (data.length === 0) {

            return (
                <div className="dropdown-result border-top">
                    <div className="text-muted m-4 text-center">No record found.</div>
                </div>
            );
        }

        const perfectScrollbarOptions = {
            wheelSpeed: 2,
            wheelPropagation: false,
        };


        const handleSelectedValue = (value, label) => event => {
            setFieldTouched(name)
            setFieldValue(name, value);
            setSearchValue(label);
            setShow(false);

            if (customOnChange) runOnChange(value, label, setFieldValue);

        }

        return (
            <div
                style={{ maxHeight: "325px", overflow: "hidden" }}
                className="quick-search-wrapper scroll ps ps--active-y"
            >
                <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="scroll"
                    style={{ maxHeight: "325px", position: "relative" }}
                >
                    <div className="dropdown-result">
                        {data.map((item, i) => {
                            return (<button className="dropdown-item" key={i} tabIndex={i} onClick={handleSelectedValue(item.value, item.label)}>{item.label}</button>)
                        })}
                    </div>
                </PerfectScrollbar>
            </div>
        );
    }

    return (
        <>

            <div className="dropdown">

                {label && <label>{label}{required && (<span style={{ color: 'red' }}> *</span>)}</label>}


                <input
                    type="hidden"
                    autoComplete="off"
                    name={name}
                    value={value || ""}
                    onChange={field.onChange}
                // {...field}
                />

                <input
                    autoComplete="off"
                    type={type}
                    name={name}
                    value={searchValue}
                    onChange={field.onChange}
                    className={getFieldCSSClasses(touched[name], errors[name])}
                    onFocus={handleInputFocus}
                    // {...field}
                    {...props}

                />



                {withFeedbackLabel && touched[name] && (
                    <FieldFeedbackLabel
                        error={errors[name]}
                        touched={touched[name]}
                        label={label}
                        type={type}
                        customFeedbackLabel={customFeedbackLabel}
                    />
                )}




                <div className={`w-100 dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg ${show ? 'show' : ''}`} style={{ position: "absolute", top: "0px", left: "0px", margin: "0px" }} >
                    <div
                        id="kt_quick_search_dropdown"
                        className={clsx("quick-search quick-search-dropdown", {
                            "quick-search-has-result": data && data.length,
                        })}
                    >
                        <div className="quick-search-form">
                            <div className="input-group">
                                <input
                                    autoFocus
                                    ref={searchFieldRef}
                                    autoComplete="off"
                                    className="form-control"
                                    // className={getFieldCSSClasses(touched[name], errors[name])}
                                    onChange={handleSearchChange}
                                    onBlur={handleSearchBlur}
                                    value={searchValue}

                                    // {...field}
                                    {...props}
                                    id={props.id ? `search-autocomplete-${props.id}` : "search-autocomplete"}

                                />

                                <div
                                    className={`input-group-append ${loading ? "spinner spinner-sm spinner-primary" : ""
                                        }")}`}
                                >
                                    <span className="input-group-text">
                                        <i
                                            style={{
                                                display:
                                                    loading && searchValue && searchValue.length > 0
                                                        ? "none"
                                                        : "flex",
                                            }}
                                            onClick={clear}
                                            className="quick-search-close ki ki-close icon-sm text-muted"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <ResultData data={data} />
                    </div>
                </div>


            </div>



        </>
    );
}
