import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { FormattedMessage } from "react-intl";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">
            {today} &copy; {process.env.REACT_APP_APP_NAME} by
          </span>
          {` `}
          <a
            href={process.env.REACT_APP_COMPANY_LINK}
            rel="noopener noreferrer"
            target="_blank"
            className="text-dark-75 text-hover-primary"
          >
            {process.env.REACT_APP_COMPANY_NAME}
          </a>
        </div>
        <div className="nav nav-dark order-1 order-md-2">
          <a
            href={`${process.env.REACT_APP_MAIN_LINK}#contact-area`}
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link pl-3 pr-0"
          >
            <FormattedMessage id="AUTH.FOOTER.CONTACT" defaultMessage="Contact" />
          </a>
        </div>
      </div>
    </div>
  );
}
