import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";

const animationStartClass = "dialog-shown";
const animationEndClass = "dialog-hidden";
const errorMessage = [
    "alert",
    "alert-danger",
    "text-white"
];
const successMessage = [
    "alert",
    "alert-success",
    "text-white"
]
const initCssClasses = [
    "w-100",
    "left-0",
    "ml-0",
    "dialog",
    "dialog-default",
    "dialog-loader",
    "dialog-top-center"
];

export function TopAlertDialog({ show, text, variant = "success" }) {
    const [cssClasses, setCssClasses] = useState(initCssClasses);

    useEffect(() => {
        // for animation start should toggle 'dialog-hidden' css class
        setCssClasses(initCssClasses);
        const timeoutId = setTimeout(() => {
            const cssClassForAdding = show
                ? animationStartClass
                : animationEndClass;
            setCssClasses([...initCssClasses, cssClassForAdding, ...(variant === "success") ? successMessage : errorMessage]);

        }, 200);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            clearTimeout(timeoutId);
        };
    }, [show, variant]);

    return (
        <Portal node={document && document.getElementById("message-portal")}>
            <div className={cssClasses.join(" ")}>{text}</div>
        </Portal>
    );
}
