import { createSlice } from "@reduxjs/toolkit";

const initialPermissionState = {
    listLoading: false,
    actionsLoading: false,
    currentPermissions: [],
    toBeAddedPermissions: [],
    toBeRemovedPermissions: [],
    lastError: null,
    message: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const permissionSlice = createSlice({
    name: "permissions",
    initialState: initialPermissionState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error.clientMessage;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // get list data
        permissionsFetched: (state, action) => {
            const { currentPermissions, toBeRemovedPermissions, toBeAddedPermissions } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.currentPermissions = currentPermissions;
            state.toBeAddedPermissions = toBeAddedPermissions;
            state.toBeRemovedPermissions = toBeRemovedPermissions;
        },
        // createPermission
        permissionRegenerated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },

        setMessage: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
        }

    }
});
