import { createSlice } from "@reduxjs/toolkit";

const initialSubAccountTypeState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    subAccountTypeForEdit: undefined,
    lastError: null,
    message: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const subAccountTypeSlice = createSlice({
    name: "subAccountTypes",
    initialState: initialSubAccountTypeState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error.clientMessage;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.message = null;

            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            state.message = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getSubAccountTypeById
        subAccountTypeFetched: (state, action) => {
            state.actionsLoading = false;
            state.subAccountTypeForEdit = action.payload.subAccountTypeForEdit;
            state.error = null;

        },
        // get list data
        subAccountTypesFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // createSubAccountType
        subAccountTypeCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.message = action.payload.msgResponse;
        },
        // updateSubAccountType
        subAccountTypeUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.message = action.payload.msgResponse;
        },
        // subAccountTypeDeleted
        subAccountTypeDeleted: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
            state.actionsLoading = false;
        },
        setMessage: (state, action) => {
            state.error = null;
            state.message = action.payload.msgResponse;
        },

    }
});
