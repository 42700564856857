import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
// import { BuilderPage } from "./pages/BuilderPage";
// import { DashboardPage } from "./pages/DashboardPage";
import { getAccountType } from "../_metronic/_helpers";
import useLazyLoad from "../hooks/useLazyLoad";
import { useSelector, shallowEqual } from "react-redux";
import { AdminUIProvider } from "./modules/Admin/AdminProvider";

const DashboardPage = useLazyLoad(() =>
  import("./modules/Dashboard/DashboardPage")
);

const Inventory = useLazyLoad(() =>
  import("./modules/Inventory")
);

const Transactions = useLazyLoad(() => import("./modules/Transactions"))
const StockAssociations = useLazyLoad(() => import("./modules/StockAssociations"))
const SingleStockAssociations = useLazyLoad(() => import("./modules/SingleStockAssociations"))

const StockAudits = useLazyLoad(() => import("./modules/StockAudits"))


const Configurations = useLazyLoad(() =>
  import("./modules/Configurations/Configurations")
);

const UserProfilepage = useLazyLoad(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const AdminPage = useLazyLoad(() => import("./modules/Admin/AdminPage"));


export default function BasePage() {

  const { authUser } = useSelector(
    ({ auth }) => ({
      authUser: auth.user,
    }),
    shallowEqual
  );

  const { accountType = undefined } = authUser;

  const [mainAccountType] = getAccountType(accountType);

  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <AdminUIProvider>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }

          <Route path="/configurations" component={Configurations} />
          <Route path="/inventory" component={Inventory} />
          <Route path="/transactions" component={Transactions} />
          <Route path="/stockAssociations" component={SingleStockAssociations} />
          <Route path="/massStockAssociations" component={StockAssociations} />
          <Route path="/stockAudits" component={StockAudits} />
          <ContentRoute path="/dashboard" component={DashboardPage} />
          {/* <ContentRoute path="/builder" component={BuilderPage} /> */}

          <Route path="/user-profile" component={UserProfilepage} />

          {accountType && mainAccountType === "administrator" && (
            <Route path="/admin" component={AdminPage} />
          )}

          <Redirect to="/error/error-v5" />
        </Switch>
      </Suspense>
    </AdminUIProvider>
  );
}
