import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl, FormattedMessage } from "react-intl";
import * as auth from "../_redux/authRedux";
import { resetPassword } from "../_redux/authCrud";
import { toAbsoluteUrl } from './../../../../_metronic/_helpers';
import SVG from "react-inlinesvg";

const initialValues = {
  newPassword: "",
  newConfirmPassword: "",
};

function ResetPassword(props) {
  const { history, match: {
    params: { token },
  } } = props;

  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);


  useEffect(() => {
    if (!token) history.goBack();
  }, [token, history])

  const ResetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().required(intl.formatMessage({
      id: "AUTH.VALIDATION.NEW_PASSWORD.REQUIRED",
    })),
    newConfirmPassword: Yup.string()
      .required(intl.formatMessage({
        id: "AUTH.VALIDATION.CONFIRM_PASSWORD.REQUIRED",
      }))
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          intl.formatMessage({
            id: "AUTH.VALIDATION.CONFIRM_PASSWORD.NOT_MATCH.NEW_PASSWORD",
          })
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const formValues = {
        ...values,
        token: token,
      }
      resetPassword(formValues)
        .then((response) => {
          let { status, data } = response;

          if (status !== 200) {
            setStatus(intl.formatMessage({
              id: "AUTH.RESET_PASSWORD.NETWORK.ERROR",
            }));
            return;
          }

          const { success, message } = data;

          if (!success) {
            setStatus(message);
          }

          setIsRequested(true);

        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(intl.formatMessage({
            id: "AUTH.RESET_PASSWORD.NETWORK.ERROR",
          }));
        });
    },
  });

  return (
    <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
      {isRequested && (
        <div className="login-form login-reset" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <span className={`svg-icon svg-icon-xxl-10x svg-icon-success`}>
              <SVG
                title="Reset Success"
                src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
              />
            </span>
            <h3 className="font-size-h2 font-weight-normal mt-5"><FormattedMessage id="AUTH.RESET_PASSWORD.SUCCESS.MESSAGE" /></h3>
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <Link to="/auth">
              <button
                type="button"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <FormattedMessage id="AUTH.GENERAL.BACK_TO_LOGIN" />
              </button>
            </Link>
          </div>
        </div>
      )}
      {!isRequested && (
        <div className="login-form login-reset" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1"><FormattedMessage id="AUTH.RESET_PASSWORD.TITLE" /></h3>
            <div className="text-muted font-weight-bold">
              <FormattedMessage id="AUTH.RESET_PASSWORD.DESC" />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="password"
                placeholder={intl.formatMessage({
                  id: "AUTH.INPUT.PASSWORD",
                })}
                name="newPassword"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "newPassword"
                )}`}
                {...formik.getFieldProps("newPassword")}
              />
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.newPassword}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                type="password"
                placeholder={intl.formatMessage({
                  id: "AUTH.INPUT.CONFIRM_PASSWORD",
                })}
                name="newConfirmPassword"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "newConfirmPassword"
                )}`}
                {...formik.getFieldProps("newConfirmPassword")}
              />
              {formik.touched.newConfirmPassword && formik.errors.newConfirmPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.newConfirmPassword}</div>
                </div>
              ) : null}
            </div>

            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_reset_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                <FormattedMessage id="AUTH.GENERAL.RESET_BUTTON" defaultMessage="Reset Password" />
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_reset_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" defaultMessage="Cancel"/>
                </button>
              </Link>
            </div>

          </form>
        </div>
      )}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
