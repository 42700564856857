import React, { createContext, useContext, useState, useEffect } from "react";
import { getStorage, setStorage } from "../../../_metronic/_helpers";

const AdminUIContext = createContext();

export function useAdminUIContext() {
    return useContext(AdminUIContext);
}

export const AdminUIConsumer = AdminUIContext.Consumer;

export function AdminUIProvider({ children }) {


    const [adminControl, setAdminControl] = useState("denied");


    useEffect(() => {
        const isAdminGranted = getStorage("a_access");
        setAdminControl(isAdminGranted || "denied");
    }, [])


    useEffect(() => {
        setStorage("a_access", adminControl);
    }, [adminControl])



    return (
        <AdminUIContext.Provider value={{ adminControl, setAdminControl }}>
            {children}
        </AdminUIContext.Provider>
    );
}
