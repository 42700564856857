import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/materials`;

// CREATE =>  POST: add a new product to the server
export function createInventory(inventory) {
    return axios.post(API_URL, { ...inventory });
}

// READ
export function getAllInventory() {
    return axios.get(API_URL);
}

export function getPaginatedInventory(queryParams) {
    return axios.post(`${API_URL}/list`, { ...queryParams });
}

export function getInventoryById(inventoryId) {
    return axios.get(`${API_URL}/${inventoryId}`);
}



// // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// // items => filtered/sorted result
// export function findProducts(queryParams) {
//     return axios.post(`${API_URL}/find`, { queryParams });
// }

// UPDATE => PUT: update the inventory on the server
export function updateInventory(inventoryId, inventory) {
    return axios.put(`${API_URL}/${inventoryId}`, { ...inventory });
}


// DELETE => delete the product from the server
export function deleteInventory(inventoryId) {
    return axios.delete(`${API_URL}/${inventoryId}`);
}
