import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";

// Configurations
import { locationSlice } from "../app/modules/Configurations/_redux/locations/locationSlice";
import { supplierSlice } from "../app/modules/Configurations/_redux/suppliers/supplierSlice";
import { manufacturerSlice } from "../app/modules/Configurations/_redux/manufacturers/manufacturerSlice";
import { categorySlice } from "../app/modules/Configurations/_redux/category/categorySlice";
import { categoryTypeSlice } from "../app/modules/Configurations/_redux/categoryTypes/categoryTypeSlice";
import { itemSlice } from "../app/modules/Configurations/_redux/items/itemSlice";
// Main
import { inventorySlice } from "../app/modules/Inventory/_redux/inventory/inventorySlice";
import { transactionSlice } from "../app/modules/Transactions/_redux/transactions/transactionSlice";
import { stockAuditSlice } from "../app/modules/StockAudits/_redux/stockAudits/stockAuditSlice";
import { stockAuditItemSlice } from "../app/modules/StockAudits/_redux/stockAuditItems/stockAuditItemSlice";

// Admin
import { permissionSlice } from "../app/modules/Admin/_redux/permissions/permissionSlice";
import { permissionSetSlice } from "../app/modules/Admin/_redux/permissionSets/permissionSetSlice";
import { subAccountTypeSlice } from "../app/modules/Admin/_redux/subAccountTypes/subAccountTypeSlice";
import { usersSlice } from "../app/modules/Admin/_redux/users/userSlice";

// Admin Dashboard
// import { adminDashboardSlice } from "../app/modules/Dashboard/_redux/adminDashboard/adminDashboardSlice";

const appReducer = combineReducers({
  // Auth
  auth: auth.reducer,

  // Configurations
  locations: locationSlice.reducer,
  suppliers: supplierSlice.reducer,
  manufacturers: manufacturerSlice.reducer,
  category: categorySlice.reducer,
  categoryTypes: categoryTypeSlice.reducer,
  items: itemSlice.reducer,
  // Main
  inventory: inventorySlice.reducer,
  transactions: transactionSlice.reducer,
  stockAudits: stockAuditSlice.reducer,
  stockAuditItems: stockAuditItemSlice.reducer,

  // Admin
  permissions: permissionSlice.reducer,
  permissionSets: permissionSetSlice.reducer,
  subAccountTypes: subAccountTypeSlice.reducer,
  users: usersSlice.reducer,
  // Admin Dashboard
  // adminDashboards: adminDashboardSlice.reducer,
});

export const rootReducer = (state, action) => {
  if (action.type === auth.actionTypes.Logout) {
    const { auth } = state;

    return appReducer({ auth }, action);
  }
  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([auth.saga()]);
}
