import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/permissionsets`;

// CREATE =>  POST: add a new product to the server
export function createPermissionSet(permissionSet) {
    return axios.post(API_URL, { ...permissionSet });
}

// READ
export function getAllPermissionSets() {
    return axios.get(API_URL);
}

export function getPaginatedPermissionSets(queryParams) {
    return axios.post(`${API_URL}/list`, { ...queryParams });
}

export function getPermissionSetById(permissionSetId) {
    return axios.get(`${API_URL}/${permissionSetId}`);
}


export function updatePermissionSet(permissionSetId, permissionSet) {
    return axios.put(`${API_URL}/${permissionSetId}`, { ...permissionSet });
}

export function deletePermissionSet(permissionSetId) {
    return axios.delete(`${API_URL}/${permissionSetId}`);
}
