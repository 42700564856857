/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
// import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import { injectIntl } from "react-intl";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import ResetPassword from "./ResetPassword";
import SVG from "react-inlinesvg"

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/intrasys-bg.png")})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <div className="d-flex flex-column align-items-center">
                  <div>
                  <img
                    alt="Logo"
                    className="max-h-40px pt-2 pb-2"
                    src={toAbsoluteUrl(`/media/logos/Intrasys-Logo-white.gif`)}
                  />
                  </div>
                  <div className="my-4">
                    <span className="svg-icon svg-icon-4x svg-icon-light">
                      <SVG
                      title=""
                        src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                      />
                    </span>
                  </div>
                  <div className="">
                    <img
                      alt="Logo"
                      className="max-h-80px"
                      src={toAbsoluteUrl(`/media/logos/ethicon.png`)}
                    />
                  </div>
                  
                </div>
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">
                  {process.env.REACT_APP_APP_NAME}
                </h3>
                <p className="font-weight-lighter text-white opacity-80">
                  -
                </p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; {process.env.REACT_APP_COPYRIGHT_YEAR} {process.env.REACT_APP_COMPANY_NAME}
                </div>
                {/* <div className="d-flex">
                  <a href={`${process.env.REACT_APP_MAIN_LINK}#how-it-works`} className="text-white">
                    <FormattedMessage id="AUTH.FOOTER.HOW_IT_WORKS" />
                  </a>
                  <a href={`${process.env.REACT_APP_MAIN_LINK}#features`} className="text-white ml-10">
                    <FormattedMessage id="AUTH.FOOTER.FEATURES" />
                  </a>
                  <a href={`${process.env.REACT_APP_MAIN_LINK}#contact-area`} className="text-white ml-10">
                    <FormattedMessage id="AUTH.FOOTER.CONTACT" />
                  </a>
                </div> */}
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Don't have an account yet?
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Sign Up!
              </Link>
            </div> */}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                {/* <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                /> */}
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/reset-password/:token"
                  component={ResetPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {process.env.REACT_APP_COPYRIGHT_YEAR} {process.env.REACT_APP_COMPANY_NAME}
              </div>
              {/* <div className="d-flex order-1 order-sm-2 my-2">
                <a href={`${process.env.REACT_APP_MAIN_LINK}#how-it-works`} className="text-dark-75 text-hover-primary">
                  <FormattedMessage id="AUTH.FOOTER.HOW_IT_WORKS" />
                </a>
                <a href={`${process.env.REACT_APP_MAIN_LINK}#features`}
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  <FormattedMessage id="AUTH.FOOTER.FEATURES" />
                </a>
                <a href={`${process.env.REACT_APP_MAIN_LINK}#contact-area`}
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  <FormattedMessage id="AUTH.FOOTER.CONTACT" />
                </a>
              </div> */}
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}


export default injectIntl((AuthPage))